import React, { Component } from "react";
import Slider from "react-slick";
import { animateScroll } from 'react-scroll';
import utility_data, { get_utility_data } from "../utilities/browser_information.js";
import PanoramicSlide from "./Panoramic_Slide.js";
import ArrowHead from "../../assets/svg/arrow-head.svg";

const settings = {
	arrows: false,
	autoplay: true,
	autoplaySpeed: 10000,
	dots: true,
	infinite: true,
	speed: 500,
	slide: ".slide",
	slidesToShow: 1,
	slidesToScroll: 1
};

export default class Panoramic_Hero extends Component {
	constructor(props){
		super(props);
		this.state = {
			data: this.props.slides[0].acf ? this.props.slides[0].acf.slides : this.props.slides,
			height: 700
		};
	}
	render(){

		return <div className="panoramic-hero allset">
			<div className="slider-container" style={{height: this.state.height + "px"}}>
				<Slider {...settings}>
					{this.state.data.map( (slide, i) => (
						<PanoramicSlide key={ i } mobileImage={ slide.mobile_image.url } desktopImage={ slide.desktop_image.url } video={slide.vimeo_url} title={ slide.title } copy={ slide.copy } cta={ slide.cta } alt={slide.desktop_image.alt} link={ slide.link } />
					))}
				</Slider>
				<div className="panoramic-hero-buttons"></div>
			</div>
			<div className="drop-arrow">
				<button aria-label="Scroll Down" onClick={()=>{ animateScroll.scrollTo(utility_data().browser_height - 200) }}></button>
				<div className="arrow">
					<div className="shaft"></div>
					<ArrowHead />
				</div>
			</div>
		</div>;
	}
}
