import React from "react";
import { ArrowLink } from "../buttons/Arrow_Link.js";
import link_realignment from "../utilities/link_realignment.js";
import BackgroundImage from "../repeating_elements/Background-Image.js";
import EnterHeader from "../repeating_elements/Enter_Header.js";
import BrowserView from "../utilities/mobileViewCheck.js"

const PanoramicSlide = ({ key, data, mobileImage, desktopImage, video, title, alt, copy, cta, link }) => (
	<div className="slide" key={"pano-hero-"+key}>
		<div className="image">
			<BackgroundImage noParallax="true" url={ desktopImage } mobileURL={ mobileImage } alt={alt} loading={ video ? "eager" : "lazy" } cover="disabled"></BackgroundImage>
			<BrowserView video={video} />
		</div>
		<div className="content">
			<div className="grid">
				<div className="grid-m-4 grid-t-10 grid-d-10">
					{ copy ? <span className="styling-eyebrow" key={"pano-eyebrow-"+key}><h1 dangerouslySetInnerHTML={{__html:title}}>{}</h1></span> : <span className="styling-eyebrow" key={ "pano-eyebrow-alt-" + key }><span></span></span> }
					{ copy ? <EnterHeader className="styling-header-2" key={"pano-title-"+key}>{copy}</EnterHeader> : <EnterHeader className="styling-header-2" key={ "pano-title-alt-" + key }>{title}</EnterHeader> }
					<div className="arrow-container"><div>{ cta ? <ArrowLink title={cta} url={link_realignment(link) || "/"} /> : null }</div></div>
				</div>
			</div>
		</div>
	</div>
);

export default PanoramicSlide;
